<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Hostname</th>
                <td colspan="2">
                  {{ asup.hostname }}
                </td>
              </tr>
              <tr>
                <th>Version</th>
                <td colspan="2">
                  {{ asup.version }}
                </td>
              </tr>
              <tr>
                <th>Serial</th>
                <td colspan="2">
                  {{ asup.serial }}
                </td>
              </tr>
              <tr v-if="asup.details.serverProperties && asup.details.serverProperties.ipAddress">
                <th>IP address</th>
                <td colspan="2">
                  {{ asup.details.serverProperties.ipAddress }}
                </td>
              </tr>
              <tr v-if="asup.details.serverProperties && asup.details.serverProperties.systemId">
                <th>System ID</th>
                <td colspan="2">
                  {{ asup.details.serverProperties.systemId }}
                </td>
              </tr>
              <tr v-if="asup.capacity">
                <th>Capacity</th>
                <td colspan="2">
                  {{ asup.capacity | prettyBytes }} ({{ asup.capacity | prettyBytes({ binary: true}) }})
                </td>
              </tr>

              <tr>
                <th>Used Capacity</th>
                <template>
                  <td>
                    {{ asup.serverUtilization }}% <br>
                    {{ asup.used | prettyBytes }} of {{ asup.capacity | prettyBytes }} <br>
                    {{ asup.used | prettyBytes({ binary: true}) }} of {{ asup.capacity | prettyBytes({ binary: true}) }} <br>
                  </td>
                  <td>
                    <apexchart
                      type="radialBar"
                      height="70"
                      width="70"
                      :options="capacityChartOptions"
                      :series="[asup.serverUtilization]"
                    />
                  </td>
                </template>
              </tr>

              <tr>
                <td colspan="3">
                  <hr>
                </td>
              </tr>

              <tr v-if="asup.details.serverProperties && asup.details.serverProperties.numberOfNodes">
                <th>Number of nodes</th>
                <td colspan="2">
                  {{ asup.details.serverProperties.numberOfNodes }}
                </td>
              </tr>
              <tr v-if="asup.details.gsan && asup.details.gsan.gcStatus">
                <th>Last GC</th>
                <td colspan="2">
                  {{ asup.details.gsan.gcStatus.endTime | formatDateTimeTz(asup.timezone) }}
                </td>
              </tr>
              <tr v-if="asup.details.serverProperties && asup.details.serverProperties.lastCheckpoint">
                <th>Last checkpoint</th>
                <td colspan="2">
                  {{ asup.details.serverProperties.lastCheckpoint | formatDateTimeTz(asup.timezone) }}
                </td>
              </tr>
              <tr v-if="asup.details.serverProperties && asup.details.serverProperties.lastValidatedCheckpoint">
                <th>Last validated checkpoint</th>
                <td colspan="2">
                  {{ asup.details.serverProperties.lastValidatedCheckpoint | formatDateTimeTz(asup.timezone) }}
                </td>
              </tr>
              <tr v-if="asup.details.serverProperties && asup.details.serverProperties.timeSinceServerInitialization">
                <th>Time since server initialization</th>
                <td colspan="2">
                  {{ asup.details.serverProperties.timeSinceServerInitialization }}
                </td>
              </tr>
              <tr v-if="asup.details.serverProperties && asup.details.serverProperties.licenseExpiration">
                <th>License Expiration</th>
                <td colspan="2">
                  {{ asup.details.serverProperties.licenseExpiration }}
                </td>
              </tr>

              <tr>
                <th>System Timezone</th>
                <td colspan="2">
                  {{ asup.timezone }}
                </td>
              </tr>
              <tr>
                <th>Last autosupport data</th>
                <td colspan="2">
                  {{ asup.updateTimestampUtc | formatDateTime }}
                </td>
              </tr>
              <tr v-if="asup.updateTimestampUtc > asup.lastBuildModelTimestamp">
                <th>Model built on (UTC)</th>
                <td colspan="2">
                  {{ asup.lastBuildModelTimestamp | formatDateTime }}<br>
                  <small class="text-hint text-danger">The displayed data is older than the latest Autosupport information. The displayed data should be updated a few minutes after the latest Autosupport data.</small>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>

      </b-col>
      <b-col>
        <b-card>
          <template #header>
            <h4 class="card-title">
              Monitor Status <small class="text-muted">({{ monitors.length }} monitors checked)</small>
            </h4>
            <div
              v-if="isMonitorOverwritten"
              class="float-right"
            >
              <b-badge
                v-b-popover.hover="'At least one monitor has been overwritten for this system.'"
                pill
                variant="info"
              >
                <small>Monitor overwritten <feather-icon icon="HelpCircleIcon" /></small>
              </b-badge>
            </div>
          </template>

          <ul
            v-if="activeMonitors.length > 0"
            style="list-style: none"
          >
            <li
              v-for="monitor in activeMonitors"
              :id="`monitor_${monitor.monitorId}`"
              :key="monitor.monitorId"
            >
              <monitor-status-icon
                :status="monitor.status"
                :text="monitor.shortMessage || monitor.exceptionMessage"
              />

              <b-popover
                custom-class="wide-popover"
                :target="`monitor_${monitor.monitorId}`"
                triggers="hover"
                :variant="popoverVariant(monitor.status)"
              >
                <template #title>
                  Detailed message
                </template>

                <span
                  v-if="monitor.message"
                  v-html="monitor.message"
                />
                <span v-else>No detailed message</span>
              </b-popover>
            </li>
          </ul>
          <div v-else>
            <monitor-status-icon
              :status="0"
              text="All monitors are OK"
            />
          </div>
        </b-card>

        <b-card>
          <template #header>
            <h4 class="card-title">
              Avamar System Check
            </h4>
          </template>

          <v-jstree
            v-if="healthTree"
            :data="healthTree"
            size="large"
            allow-transition
            whole-row
            text-field-name="name"
            value-field-name="calculatedStatus"
            children-field-name="childs"
          >
            <template scope="_">
              <div
                style="display: inherit; width: 200px"
              >
                <feather-icon
                  :class="{ 'danger': _.model.calculatedStatus === 30, 'success': _.model.calculatedStatus === 0}"
                  icon="CircleIcon"
                />
                <span
                  class="ml-1"
                  v-html="_.model.name"
                />
              </div>
            </template>
          </v-jstree>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, VBPopover, BPopover, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import VJstree from 'vue-jstree'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'

import AsupStatusService from '@/service/asupStatus.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    MonitorStatusIcon,
    apexchart: VueApexCharts,
    VJstree,
    BPopover,
    BBadge,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monitors: [],
      isLoading: false,
      capacityChartOptions: {
        chart: {
          type: 'radialBar',
          width: 50,
          height: 50,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '50%',
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    activeMonitors() {
      return this.monitors.filter(x => x.status !== 0)
    },
    isMonitorOverwritten() {
      if (this.isHost && this.asup.isHostMonitorOverwritten) {
        return true
      }
      if (!this.isHost && this.asup.isTenantMonitorOverwritten) {
        return true
      }

      return false
    },
    healthTree() {
      return this.asup.healthTreeItems.map(x => {
        // eslint-disable-next-line no-param-reassign
        x.opened = true // open all leafs per default
        return x
      })
    },
  },
  created() {
    AsupStatusService.getActiveListAsync({ asupSystemId: this.asup.id })
      .then(result => {
        this.monitors = result.items
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    popoverVariant(status) {
      switch (status) {
        case 0: return 'success'
        case 10: return 'info'
        case 20: return 'warning'
        case 30:
          return 'danger'
        default: return 'danger'
      }
    },
  },
}
</script>

<style scoped>
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
</style>
